import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Coordinates } from 'shared-types'
import { useCustomer, useToast } from '~/hooks'
import { Client } from '~/customClients/client'
import {
  UserDeliveryMethods,
  UserDeliveryPostcodeProviderContext,
} from './UserDeliveryPostcodeProvider.types'

const PostcodeConfigureDrawer = dynamic(
  async () => {
    const mod = await import('~/components/PostcodeConfigureDrawer')
    return mod.PostcodeConfigureDrawer
  },
  { ssr: false }
)

export const UserDeliveryPostcodeContext =
  createContext<UserDeliveryPostcodeProviderContext>(null)

const fetchNearestStore = async (coordinates, session: Session, sku) => {
  const stores = await Client.store.getNearestStore(
    coordinates,
    session,
    1,
    sku
  )
  return stores.getValue()?.[0]
}

const PREFERRED_STORE_CACHE_KEY = 'preferred-store'

export const UserDeliveryPostcodeProvider = ({
  children,
}: PropsWithChildren) => {
  const router = useRouter()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [isAuthDrawerOpen, setIsAuthDrawerOpen] = useState(false)
  const [deliveryMethods, setDeliveryMethods] =
    useState<UserDeliveryMethods[]>()
  const [freightServiceMessage, setFreightServiceMessage] = useState<string>('')
  const [freightServiceErrorCode, setFreightServiceErrorCode] =
    useState<number>()
  const { customer } = useCustomer()
  const { data: session, update } = useSession()
  const queryClient = useQueryClient()
  const [sku, setSku] = useState('')
  const [suburb, setSuburb] = useState('')
  const [productPrice, setProductPrice] = useState()
  const userStoreKey = session?.token?.storeKey || customer?.preferredStoreKey
  const hasOpened = useRef(false)
  hasOpened.current = hasOpened.current || drawerIsOpen || !!userStoreKey
  const [isDefaultStoreSet, setIsDefaultStoreSet] = useState(false)

  const setStoreByStoreKey = useCallback(
    async (storeKey) => {
      await update({
        ...session,
        token: {
          ...session.token,
          storeKey,
          sku,
        },
      })
    },
    [session, update, sku]
  )

  const setPreferredStoreByLatLong = useCallback(
    async (location: Coordinates) => {
      const store = await fetchNearestStore(location, session, sku)
      if (store) {
        queryClient.setQueryData(
          [PREFERRED_STORE_CACHE_KEY, store.storeKey],
          store
        )
        await setStoreByStoreKey(store.storeKey)
        if (!isDefaultStoreSet) {
          await router.replace(router.asPath)
          setIsDefaultStoreSet(true)
        }
      }
    },
    [isDefaultStoreSet, queryClient, router, session, setStoreByStoreKey, sku]
  )

  const postcode =
    session?.token?.deliveryPostcode || customer?.deliveryPreviewPostcode

  const city = session?.token?.deliveryCity || customer?.deliveryPreviewCity

  const state = session?.token?.deliveryState || ''

  const setPostcodeAndCity = useCallback(
    async (postcode: string, city: string, state?: string) => {
      await update({
        ...session,
        token: {
          ...session.token,
          deliveryPostcode: postcode,
          deliveryCity: city,
          deliveryState: state,
        },
      })
    },
    [session, update]
  )

  const { showNotification, closeNotification } = useToast()

  const setUserPostcode = useCallback(
    async (postcode: string, city: string, state?: string) => {
      if (!postcode || !city || !state) {
        return
      }
      await setPostcodeAndCity(postcode, city, state)
      if (router.asPath === '/store-finder') {
        setDrawerIsOpen(false)
        return
      }

      if (isDefaultStoreSet) {
        await router.replace(router.asPath)
      }
    },
    [isDefaultStoreSet, router, setPostcodeAndCity, setDrawerIsOpen]
  )

  const handleDrawerOpen = useCallback(
    (sku, productPriceIncoming) => {
      setProductPrice(productPriceIncoming)
      setSku(sku)
      setDrawerIsOpen(true)
    },
    [setSku]
  )

  const handleDrawerClose = useCallback(() => {
    setDrawerIsOpen(false)
  }, [setDrawerIsOpen])

  const setDeliveryPreviewPostcode = useCallback(
    async (postcode: string, city: string, state?: string) => {
      try {
        await setUserPostcode(postcode, city, state)
        showNotification({
          children: (
            <span>
              You&apos;ve selected{' '}
              <span className='font-extrabold'>{postcode}</span> as your
              postcode
            </span>
          ),
          autoClose: true,
          id: 'postcode-success',
          type: 'default',
          onClose: closeNotification,
        })
      } catch (e) {
        showNotification({
          children: `Oops! Something went wrong while setting your postcode`,
          autoClose: true,
          id: 'postcode-error',
          type: 'error',
          onClose: closeNotification,
        })
      }
    },
    [closeNotification, setUserPostcode, showNotification]
  )

  const getAvilableShippingCodes = useCallback(async () => {
    if (!postcode || !sku || !state) {
      setDeliveryMethods([])
      setFreightServiceMessage('')
      setFreightServiceErrorCode(0)

      return
    }

    const response = await Client.store.getDeliverToPostcodeAvailabilityStatus(
      session,
      postcode,
      sku,
      productPrice
    )

    const fullBasketStatus = response
      ? response.getValueSafe()?.deliveryMethods.length > 0
      : false

    const deliveryMethods = response
      ? response.getValueSafe()?.deliveryMethods.map((item) => {
          return {
            deliveryLabel: item.deliveryLabel,
            deliveryCharge: item.deliveryCharge,
            fullBasket: true,
            deliveryMethod: item.deliveryMethod,
            collectionStore: item.collectionStore,
          }
        })
      : []

    const genericFreightServiceErrorcode = 793

    const originalfreightServiceErrorCode = response
      ? response.getValueSafe()?.freightServiceErrorCode
      : 0

    let freightServiceErrorCode = 0

    if (response) {
      if (fullBasketStatus) {
        freightServiceErrorCode =
          response.getValueSafe()?.freightServiceErrorCode || 0
      } else if (response.getValueSafe()?.freightServiceErrorCode >= 0) {
        freightServiceErrorCode = genericFreightServiceErrorcode
      }
    }

    let freightServiceMessage = ''

    if (response) {
      const message = response.getValueSafe()?.freightServiceMessage || ''

      if (fullBasketStatus && originalfreightServiceErrorCode === 0) {
        freightServiceMessage = message
      } else if (!fullBasketStatus && originalfreightServiceErrorCode === 0) {
        freightServiceMessage =
          'Sorry we are unable to deliver this product to this location'
      } else {
        freightServiceMessage = message
      }
    }

    setDeliveryMethods(deliveryMethods as UserDeliveryMethods[])

    setFreightServiceMessage(freightServiceMessage)
    setFreightServiceErrorCode(freightServiceErrorCode)
  }, [postcode, session, sku, productPrice, state])

  const handleGetDeliveries = useCallback(
    async (sku, productPriceIncoming, isForWipe) => {
      if (!isForWipe) {
        setSku(sku)
        setProductPrice(productPriceIncoming)
        getAvilableShippingCodes()
      } else {
        setDeliveryMethods([])
        setFreightServiceMessage('')
        setFreightServiceErrorCode(null)
      }
    },
    [setSku, setProductPrice, getAvilableShippingCodes]
  )

  useEffect(() => {
    getAvilableShippingCodes()
  }, [getAvilableShippingCodes])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleDrawerClose)
    return () => {
      router.events.off('routeChangeComplete', handleDrawerClose)
    }
  }, [router, handleDrawerClose])

  const value = useMemo<UserDeliveryPostcodeProviderContext>(() => {
    return {
      postcode,
      city,
      promptUserPostcodeDrawer: handleDrawerOpen,
      findDeliveryOptionsNoPrompt: handleGetDeliveries,
      setDeliveryPreviewPostcode,
      deliveryMethods,
      freightServiceMessage,
      freightServiceErrorCode,
      sku,
      setSku,
      setPreferredStoreByLatLong,
      setStoreByStoreKey,
      isAuthDrawerOpen,
      setIsAuthDrawerOpen,
      postCodeDrawerIsOpen: drawerIsOpen,
      suburb,
      setSuburb,
      state,
    }
  }, [
    city,
    deliveryMethods,
    drawerIsOpen,
    handleDrawerOpen,
    handleGetDeliveries,
    setSku,
    isAuthDrawerOpen,
    postcode,
    setDeliveryPreviewPostcode,
    setPreferredStoreByLatLong,
    setStoreByStoreKey,
    sku,
    freightServiceMessage,
    freightServiceErrorCode,
    suburb,
    setSuburb,
    state,
  ])

  return (
    <UserDeliveryPostcodeContext.Provider value={value}>
      {children}

      {hasOpened.current && (
        <PostcodeConfigureDrawer
          open={drawerIsOpen}
          onClose={handleDrawerClose}
          onChangePostcode={setDeliveryPreviewPostcode}
          editOnly={false}
          postcode={postcode}
          city={city}
          deliveryMethods={deliveryMethods}
          setPreferredStoreByLatLong={setPreferredStoreByLatLong}
          sku={sku}
          freightServiceMessage={freightServiceMessage}
          freightServiceErrorCode={freightServiceErrorCode}
        />
      )}
    </UserDeliveryPostcodeContext.Provider>
  )
}
