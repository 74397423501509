import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { CheckEmailRequest } from 'shared-types'
import { useSession } from '~/hooks'
import { Client } from '~/customClients/client'
import { ValidationProviderProps } from './ValidationProvider.types'

export const defaultValidationContextValue = {}
export const ValidationProviderContext = createContext<ValidationProviderProps>(
  defaultValidationContextValue
)

export const ValidationProvider = ({ children }: PropsWithChildren) => {
  const { session } = useSession()
  const [errorEmail, setErrorEmail] = useState<string | null>(null)

  const deepEmailCheck = useCallback(
    async (payload: CheckEmailRequest) => {
      if (!session) {
        return false
      }
      const data = await Client.account.deepEmailChecker(session, payload)

      if (data.getErrorSafe()) {
        setErrorEmail(data.getErrorSafe().body?.message)
        return false
      }

      if (data.getValueSafe().isEmailValid) {
        setErrorEmail(null)
      }
      return true
    },
    [session]
  )

  const result = useMemo(() => {
    return {
      errorEmail,
      setErrorEmail,
      deepEmailCheck,
    }
  }, [errorEmail, setErrorEmail, deepEmailCheck])

  return (
    <ValidationProviderContext.Provider value={result}>
      {children}
    </ValidationProviderContext.Provider>
  )
}
