import { formatPriceForDataLayer } from '.'

export const formatLastCartItemForDataLayer = (item) => {
  if (item !== null && item !== undefined) {
    return {
      id: item?.variant?.sku,
      price: Number(
        formatPriceForDataLayer(item?.price?.value?.centAmount || 0)
      ),
      quantity: item.quantity,
    }
  }
  return []
}
