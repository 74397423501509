/* eslint-disable camelcase */
import { GTMEvent, PageLoadDTO } from '~/lib/gtm/declarations'

export const adaptPageLoad = ({
  pageType = '',
  isSignedIn,
  preferred_store,
  delivery_postcode,
  customer_group,
  customer_type,
}): PageLoadDTO => {
  return {
    event: GTMEvent.PAGE_LOAD,
    user_status: isSignedIn ? 'Logged in' : 'Anonymous',
    page_type: pageType.toLowerCase(),
    preferred_store: preferred_store || '',
    delivery_postcode,
    customer_group,
    customer_type,
  }
}
