declare global {
  interface Window {
    braze?: {
      wipeData: () => void
      openSession: () => void
      changeUser: (userId: string) => string
    }
  }
}

export class Braze {
  static changeUser = (salesforceId: string) => {
    window.braze?.changeUser?.(salesforceId)
  }

  static anonymize = () => {
    window.braze?.wipeData?.()
    window.braze?.openSession?.()
  }
}
