import { LineItem } from 'shared-types'
import { BrazeProductDTO } from '~/lib'
import { formatPriceForDataLayer } from '~/lib/gtm/helpers'

export const adaptBrazeProduct = (item: LineItem): BrazeProductDTO => {
  return {
    item_id: item?.variant?.sku,
    price: item?.price?.value?.centAmount
      ? Number(
          formatPriceForDataLayer(item.price.value.centAmount / item.quantity)
        )
      : 0,
    quantity: item?.quantity,
  }
}

export const adaptBrazeProducts = (items: LineItem[]) => {
  return items.map((item) => {
    return adaptBrazeProduct(item)
  })
}
