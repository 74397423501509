import { IClient } from '../types/Client'
import { AccountClient } from './accountClient'
import { AddressfinderClient } from './addressfinderClient'
import { ArticlesClient } from './articlesClient'
import { AuthClient } from './authClient'
import { BladeClient } from './bladeClient'
import { CartClient } from './cartClient'
import { CheckoutClient } from './checkoutClient'
import { CMSClient } from './cmsClient'
import { ExperianClient } from './experianClient'
import { ListsClient } from './listsClient'
import { OrderClient } from './orderClient'
import { ProductsClient } from './productsClient'
import { ProjectsClient } from './projectsClient'
import { SearchResultClient } from './searchClient'
import { StoreClient } from './storeClient'

export const Client: IClient = {
  cms: new CMSClient(),
  cart: new CartClient(),
  checkout: new CheckoutClient(),
  account: new AccountClient(),
  auth: new AuthClient(),
  addressfinder: new AddressfinderClient(),
  order: new OrderClient(),
  productList: new ProductsClient(),
  projectList: new ProjectsClient(),
  articleList: new ArticlesClient(),
  store: new StoreClient(),
  searchResults: new SearchResultClient(),
  lists: new ListsClient(),
  blade: new BladeClient(),
  experian: new ExperianClient(),
}
