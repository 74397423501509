import { Cart, LineItem } from 'shared-types'
import { GTMEvent, BrazeAddToCartDTO, BrazeProductDTO } from '~/lib'
import { adaptBrazeProduct, adaptBrazeProducts } from './adaptBrazeProduct'

interface AdaptBrazeAddToCartProps {
  addedItems: {
    item: LineItem
    affectedQuantity: number
  }[]
  cart: Cart
}

const mapModifiedItemsToDataLayerProducts = (
  addedItems: AdaptBrazeAddToCartProps['addedItems'],
  cartProducts: BrazeProductDTO[]
) => {
  return addedItems.map((addedItem) => {
    const cartItem = addedItem.item
    const product = adaptBrazeProduct(cartItem)
    const cartProduct = cartProducts.find((tmpCartProduct) => {
      return tmpCartProduct.item_id === product.item_id
    })
    product.price = cartProduct?.price || product.price || 0
    product.quantity = addedItem.affectedQuantity
    return product
  })
}

export const adaptBrazeAddToCart = ({
  addedItems,
  cart,
}: AdaptBrazeAddToCartProps): BrazeAddToCartDTO => {
  const cartProducts = adaptBrazeProducts(cart?.lineItems || [])
  const addedProducts = mapModifiedItemsToDataLayerProducts(
    addedItems,
    cartProducts
  )

  return {
    event: GTMEvent.BRAZE_ADD_TO_CART,
    ecommerce: cart
      ? {
          currency: cart?.totalPrice?.currencyCode,
          cart_items: cartProducts,
          items_added: addedProducts,
        }
      : null,
  }
}
