import { Cart, LineItem } from 'shared-types'
import { GTMEvent, BrazeRemoveFromCartDTO, BrazeProductDTO } from '~/lib'
import { adaptBrazeProduct, adaptBrazeProducts } from './adaptBrazeProduct'

interface AdaptBrazeRemoveFromCartProps {
  removedItems: {
    item: LineItem
    affectedQuantity: number
  }[]
  cart: Cart
}

const mapModifiedItemsToDataLayerProducts = (
  removedItems: AdaptBrazeRemoveFromCartProps['removedItems'],
  cartProducts: BrazeProductDTO[]
) => {
  return removedItems.map((removedItem) => {
    const cartItem = removedItem.item
    const product = adaptBrazeProduct(cartItem)
    const cartProduct = cartProducts.find((tmpCartProduct) => {
      return tmpCartProduct.item_id === product.item_id
    })
    product.price = cartProduct?.price || product.price || 0
    product.quantity = removedItem.affectedQuantity
    return product
  })
}

export const adaptBrazeRemoveFromCart = ({
  removedItems,
  cart,
}: AdaptBrazeRemoveFromCartProps): BrazeRemoveFromCartDTO => {
  const cartProducts = adaptBrazeProducts(cart?.lineItems || [])
  const removedProducts = mapModifiedItemsToDataLayerProducts(
    removedItems,
    cartProducts
  )
  return {
    event: GTMEvent.BRAZE_REMOVE_FROM_CART,
    ecommerce: cart
      ? {
          currency: cart?.totalPrice?.currencyCode,
          cart_items: cartProducts,
          items_removed: removedProducts,
        }
      : null,
  }
}
