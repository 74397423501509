import { GTMEvent } from '~/lib'

const generateUserIdentity = (customerData, defaultCustomerAddressData) => {
  return customerData || defaultCustomerAddressData || ''
}

const generateAddress = (defaultCustomerAddress) => {
  return (
    defaultCustomerAddress?.additionalAddressInfo ||
    `${defaultCustomerAddress?.addressLine1 ?? ''} ${
      defaultCustomerAddress?.city ?? ''
    } ${defaultCustomerAddress?.postalCode ?? ''} ${
      defaultCustomerAddress?.country ?? ''
    }`.trim() ||
    ''
  )
}

export const adaptUserIdentify = ({ customer, defaultCustomerAddress }) => {
  const userIdentityObj = {
    event: GTMEvent.USER_IDENTIFY,
    email: generateUserIdentity(customer?.email, defaultCustomerAddress?.email),
    emailSha256: generateUserIdentity(
      customer?.emailSha256,
      defaultCustomerAddress?.emailSha256
    ),
    first_name: generateUserIdentity(
      customer?.firstName,
      defaultCustomerAddress?.firstName
    ),
    firstnameSha256: generateUserIdentity(
      customer?.firstnameSha256,
      defaultCustomerAddress?.firstnameSha256
    ),
    last_name: generateUserIdentity(
      customer?.lastName,
      defaultCustomerAddress?.lastName
    ),
    lastnameSha256: generateUserIdentity(
      customer?.lastnameSha256,
      defaultCustomerAddress?.lastnameSha256
    ),
    city: defaultCustomerAddress?.city || '',
    citySha256: generateUserIdentity(
      customer?.citySha256,
      defaultCustomerAddress?.citySha256
    ),
    zip: defaultCustomerAddress?.postalCode || '',
    zipSha256: generateUserIdentity(
      customer?.zipSha256,
      defaultCustomerAddress?.zipSha256
    ),
    phone: generateUserIdentity(
      customer?.country_code === 'AU'
        ? customer?.phoneNumber?.toString()?.replace(/0/, '61')
        : customer?.phoneNumber?.toString()?.replace(/0/, '64'),
      defaultCustomerAddress?.country === 'AU'
        ? defaultCustomerAddress?.phone?.toString()?.replace(/0/, '61')
        : defaultCustomerAddress?.phone?.toString()?.replace(/0/, '64')
    ),
    phoneSha256: generateUserIdentity(
      customer?.phoneSha256,
      defaultCustomerAddress?.phoneSha256
    ),
    country_code: defaultCustomerAddress?.country?.toLowerCase() || '',
    countrycodeSha256: generateUserIdentity(
      customer?.countrycodeSha256,
      defaultCustomerAddress?.countrycodeSha256
    ),
    state:
      defaultCustomerAddress?.state || defaultCustomerAddress?.region || '',
    st: generateUserIdentity(
      customer?.stateSha256,
      defaultCustomerAddress?.stateSha256
    ),
    address: generateAddress(defaultCustomerAddress),
    ad: generateUserIdentity(
      customer?.addressSha256,
      defaultCustomerAddress?.addressSha256
    ),
    customer_id: generateUserIdentity(customer?.id, ''),
    customer_salesforce_id: generateUserIdentity(customer?.salesforceId, ''),
  }

  return Object.keys(userIdentityObj)
    .filter((value) => {
      return userIdentityObj[value]
    })
    .reduce((accumulator, currentValue) => {
      return {
        ...accumulator,
        [currentValue]: userIdentityObj[currentValue],
      }
    }, {})
}
