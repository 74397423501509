import { useCallback, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import algoliaSearchInsights from 'search-insights'
import { LineItem, ProductSummary } from 'shared-types'
import { BaseMoney } from 'shared-types/src/commercetools'
import { FilterMapProps } from '~/components/FiltersDrawer'
import { usePage } from '~/hooks'
import { useSession } from '../useSession'

const ALGOLIA_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INDEX
const dollarsToCentsDenominator = 100
const ALGOLIA_MAX_PRODUCT_COUNT = 20
const ALGOLIA_STORAGE_KEY = 'algolia--queryid'

const EVENT_TYPES = ['conversion', 'click', 'view'] as const
const ALGOLIA_FILTERS_EVENT_NAMES = {
  conversion: 'prod_conversion_filter',
  click: 'prod_clicked_filter',
  view: 'prod_viewed_filters',
} as const
const ALGOLIA_FILTERS_EVENT_TYPES = {
  conversion: 'convertedFilters',
  click: 'clickedFilters',
  view: 'viewedFilters',
} as const
type EventType = (typeof EVENT_TYPES)[number]

export const initAlgoliaClient = () => {
  algoliaSearchInsights('init', {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  })
}

export const useAlgoliaSearchInsignts = () => {
  const { session } = useSession()
  const { countryCode } = usePage()
  const [latestQueryId, setLatestQueryId] =
    useLocalStorage<string>(ALGOLIA_STORAGE_KEY)

  const algoliaCountryIndex = useMemo(() => {
    return countryCode === 'AU'
      ? `${ALGOLIA_INDEX}_jrau`
      : `${ALGOLIA_INDEX}_jrnz`
  }, [countryCode])

  const loadQueryIDsFromStorage = useCallback(() => {
    let queryId: string
    try {
      queryId = JSON.parse(localStorage.getItem(ALGOLIA_STORAGE_KEY))
    } catch {
      queryId = localStorage.getItem(ALGOLIA_STORAGE_KEY)
    }
    setLatestQueryId(queryId)
    return queryId
  }, [setLatestQueryId])

  const removeQueryIDFromStorage = useCallback(() => {
    try {
      localStorage.removeItem(ALGOLIA_STORAGE_KEY)
      setLatestQueryId(undefined)
    } catch (error) {
      console.error('Error removing Algolia query ID from localStorage:', error)
    }
  }, [setLatestQueryId])

  const updateLatestQueryId = useCallback(
    (queryId: string) => {
      if (latestQueryId !== queryId) {
        setLatestQueryId(queryId)
      }
    },
    [latestQueryId, setLatestQueryId]
  )

  const userId = useMemo(() => {
    if (session?.token?.customerId) {
      return session?.token?.customerId
        ? `user-${session.token.customerId}`
        : undefined
    }
    return session?.token?.anonymousId
      ? `anonymous-${session.token.anonymousId}`
      : undefined
  }, [session])

  const priceValue = (price: BaseMoney) => {
    return price?.centAmount ? price.centAmount / dollarsToCentsDenominator : 0
  }

  const buildProductEventData = useCallback(
    (products: ProductSummary[]) => {
      const filtered = products?.filter((p) => {
        return !!p.listId
      })

      if (!userId || !filtered?.length) {
        return null
      }

      return {
        userToken: userId,
        index: algoliaCountryIndex,
        objectIDs: filtered.map((p) => {
          return p.listId
        }),
      }
    },
    [userId]
  )

  const buildAddToCartEventData = useCallback(
    (
      skus: string[],
      lineItems: LineItem[],
      totalPrice: BaseMoney,
      queryId?: string
    ) => {
      if (!userId || !lineItems?.length) {
        return null
      }

      const productIds = []
      const lineItemsAddedToCart = lineItems.filter((p) => {
        return skus.includes(p.variant.sku)
      })

      const productData = lineItemsAddedToCart.map((p) => {
        const price = priceValue(p.price.value)
        const discount = priceValue(p.price.discounted?.value)

        productIds.push(p.productId)

        return {
          queryID: p.queryId || queryId || p.algoliaQueryId,
          price: (price / p.quantity).toFixed(2),
          ...(discount && { discount: discount.toFixed(2) }),
          quantity: p.quantity,
        }
      })

      return {
        userToken: userId,
        index: algoliaCountryIndex,
        objectIDs: productIds,
        objectData: productData,
        currency: totalPrice.currencyCode,
      }
    },
    [userId]
  )

  const buildPurchaseEventData = useCallback(
    (lineItems: LineItem[], totalPrice: BaseMoney, queryId?: string) => {
      if (!userId || !lineItems?.length) {
        return null
      }

      const productIds = []
      let value = 0
      const productData = lineItems.map((p) => {
        const price = priceValue(p.price.value)
        const discount = priceValue(p.price.discounted?.value)

        productIds.push(p.productId)
        value += price

        return {
          queryID: p.queryId || queryId || p.algoliaQueryId,
          price: (price / p.quantity).toFixed(2),
          ...(discount && { discount: discount.toFixed(2) }),
          quantity: p.quantity,
        }
      })

      return {
        userToken: userId,
        index: algoliaCountryIndex,
        objectIDs: productIds,
        objectData: productData,
        currency: totalPrice.currencyCode,
        value: value.toFixed(2),
      }
    },
    [userId]
  )

  const sendClickEvent = useCallback(
    (products: ProductSummary[], positions?: number[], queryID = '') => {
      const data = buildProductEventData(products)
      if (!data) {
        return
      }

      if (positions?.length && queryID) {
        setLatestQueryId(queryID)
        algoliaSearchInsights('clickedObjectIDsAfterSearch', {
          queryID,
          positions,
          eventName: 'prod_click_after_search',
          ...data,
        })
      }

      queryID.length === 0 &&
        algoliaSearchInsights('clickedObjectIDs', {
          eventName: 'prod_click',
          ...data,
        })
    },
    [setLatestQueryId, buildProductEventData]
  )

  const sendViewProductsEvent = useCallback(
    (products: ProductSummary[]) => {
      const firstTwentyProducts = products.slice(0, ALGOLIA_MAX_PRODUCT_COUNT)
      const data = buildProductEventData(firstTwentyProducts)

      if (!data) {
        return
      }

      algoliaSearchInsights('viewedObjectIDs', {
        eventName: 'prod_view',
        ...data,
      })
    },
    [buildProductEventData]
  )

  const sendConversionEvent = useCallback(
    (products: ProductSummary[], queryID = '') => {
      const data = buildProductEventData(products)

      if (!data) {
        return
      }

      if (queryID) {
        algoliaSearchInsights('convertedObjectIDsAfterSearch', {
          eventName: 'prod_converted_after_search',
          queryID,
          ...data,
        })
        return
      }

      algoliaSearchInsights('convertedObjectIDs', {
        eventName: 'prod_converted',
        ...data,
      })
    },
    [buildProductEventData]
  )

  const sendAddToCartEvent = useCallback(
    (
      skus: string[],
      lineItems: LineItem[],
      totalPrice: BaseMoney,
      queryID = ''
    ) => {
      const data = buildAddToCartEventData(skus, lineItems, totalPrice)

      if (!data) {
        return
      }

      if (queryID) {
        algoliaSearchInsights('addedToCartObjectIDsAfterSearch', {
          eventName: 'prod_add_to_cart_after_search',
          queryID,
          ...data,
        })
        return
      }

      algoliaSearchInsights('addedToCartObjectIDs', {
        eventName: 'prod_add_to_cart',
        ...data,
      })
    },
    [buildAddToCartEventData]
  )

  const sendPurchaseEvent = useCallback(
    (lineItems: LineItem[], totalPrice: BaseMoney, queryId?: string) => {
      const data = buildPurchaseEventData(lineItems, totalPrice, queryId)

      if (!data) {
        return
      }

      const hasQueryId = data.objectData.some((o) => {
        return !!o.queryID
      })

      if (hasQueryId) {
        algoliaSearchInsights('purchasedObjectIDsAfterSearch', {
          eventName: 'prod_purchased_after_search',
          ...data,
        })
        return
      }

      algoliaSearchInsights('purchasedObjectIDs', {
        eventName: 'prod_purchased',
        ...data,
      })
    },
    [buildPurchaseEventData]
  )

  const sendFilterEvent = useCallback(
    (filterMap?: FilterMapProps, eventType: EventType = 'view') => {
      if (!userId || !filterMap) {
        return
      }

      const filters = Object.keys(filterMap).map((mkey: string) => {
        const mvalue = !filterMap[mkey]?.includes('')
          ? filterMap[mkey].join(',')
          : 'Search All Products'

        return `${mkey}:${mvalue}`
      })

      if (!filters.length) {
        return
      }

      algoliaSearchInsights(ALGOLIA_FILTERS_EVENT_TYPES[eventType], {
        userToken: userId,
        index: algoliaCountryIndex,
        eventName: ALGOLIA_FILTERS_EVENT_NAMES[eventType],
        filters,
      })
    },
    [userId]
  )

  return {
    latestQueryId,
    initAlgoliaClient,
    removeQueryIDFromStorage,
    sendClickEvent,
    sendViewProductsEvent,
    sendConversionEvent,
    sendAddToCartEvent,
    sendPurchaseEvent,
    sendFilterEvent,
    updateLatestQueryId,
    loadQueryIDsFromStorage,
  }
}
