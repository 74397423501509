import { CartContentDTO, GTMEvent } from '~/lib'
import {
  formatPriceForDataLayer,
  formatLastCartItemForDataLayer,
} from '~/lib/gtm/helpers'

export const adaptCartContent = (cart): CartContentDTO => {
  const latestCartItem =
    cart?.lineItems?.at(-1) &&
    formatLastCartItemForDataLayer(cart.lineItems.at(-1))

  return {
    event: GTMEvent.CART_CONTENTS_PUSH,
    cart_total: formatPriceForDataLayer(cart?.totalPrice?.centAmount) ?? 0,
    cart_num_items: cart?.lineItems?.length ?? 0,
    cart_items:
      cart?.lineItems?.map((item) => {
        return {
          id: item?.variant?.sku,
          quantity: item?.quantity,
          price: item?.price?.value?.centAmount
            ? Number(
                formatPriceForDataLayer(
                  item.price.value.centAmount / item.quantity
                )
              )
            : 0,
        }
      }) ?? [],
    latest_cart_item: !Array.isArray(latestCartItem) ? [latestCartItem] : [],
  }
}
