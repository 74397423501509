/* eslint-disable camelcase */
import { Category } from './category'
import { Image } from './image'
import { ProductTag } from './product'
import { RichTextContent } from './richtext'
import { SalePrice } from './saleprice'

export interface SectionBase extends Record<string, unknown> {
  __typename: string
  id: string
}

export enum PageType {
  FullWidth = 'Full Width',
  Category = 'Category',
  ArticleListing = 'Article Listing',
  Stores = 'Stores',
  MakerhubWorkshop = 'Makerhub Workshop',
  PhysicalProduct = 'Physical Product',
  MakerhubArticle = 'Makerhub Article',
  MakerhubProjectDetail = 'Makerhub Project Detail',
  Checkout = 'Checkout',
  OrderConfirm = 'OrderConfirm',
  NotFound = 'Not Found',
  ProjectListing = 'Project Listing',
  MyAccount = 'My Account',
  Makerhub = 'Makerhub',
  MakerHubCommunity = 'Makerhub Community',
  MakerhubCompetitions = 'Makerhub Competitions',
  MakerHubMyProjects = 'Makerhub My Projects',
  Lists = 'lists',
  ListDetails = 'listDetails',
  Compare = 'compare',
  StoreFinder = 'StoreFinder',
  Bespoke = 'Bespoke',
}

export const indexablePageTypes = [
  'Full Width',
  'Category',
  'Category Article',
  'Stores',
  'Makerhub Workshop',
  'Physical Product',
  'Makerhub Article',
  'Makerhub Project Detail',
  'Makerhub Community',
  'Makerhub Competitions',
  'Makerhub My Projects',
  'Makerhub',
  'Article Listing',
  'Project Listing',
  'StoreFinder',
]

export const pageTypeMappedData = {
  'Full Width': 'home',
  Category: 'category',
  'Makerhub Workshop': 'workshops',
  'Physical Product': 'product',
  'Makerhub Article': 'article',
  'Makerhub Project Detail': 'project',
  Checkout: 'checkout',
  OrderConfirm: 'purchase',
  'Project Listing': 'projects',
  Stores: 'stores',
  'Not Found': 'other',
  'Article Listing': 'articles',
  'My Account': 'my account',
  Makerhub: 'makerhub',
  'Makerhub Community': 'community',
  'Makerhub Competitions': 'competitions',
  'Makerhub My Projects': 'my-projects',
  lists: 'lists',
  listDetails: 'list-details',
  compare: 'compare',
  StoreFinder: 'other',
  Bespoke: 'home',
}

export const queryParamsToIgnoreForIndexes = ['domain', 'limit', 'slug', 'p']

export interface SectionProps extends SectionBase {
  width?: 'full' | 'wide' | 'medium' | 'narrow' | 'super-narrow'
}

export interface PromoProduct {
  title: string
  sku?: string
  description?: string
  price?: SalePrice
  action: {
    href: string
    title: string
    accessibleTitle: string
  }
  backgroundColor: string
  backgroundImage: Image
  backgroundPosition: {
    y: 'bottom' | 'center' | 'top'
    x: 'left' | 'center' | 'right'
  }
  backgroundSize: 'cover' | 'contain'
  textColor: 'dark' | 'light' | string
  tags?: Array<ProductTag>
  index?: number
  promotionId?: string
  promotionName?: string
  creativeName?: string
  creativeSlot?: string
  imagePriority?: boolean
  category?: Category
  category1Name?: string
  category2Name?: string
  category3Name?: string
  category4Name?: string
  category5Name?: string
  listId?: string
  listName?: string
  brandName?: string
  item_status_tags?: string
  productId?: string
  mobileBackgroundImage?: Image
  wasText?: string
  saveText?: string
  slug?: string
  richTextContent?: RichTextContent
}

export type SiteNavigationItem = {
  categoryId: string
  categorySku: string
  title: string
  url: string
  children?: SiteNavigationItem[]
  promoProduct?: PromoProduct
}

export type ProductReview = {
  name: string
  reviewBody: string
  reviewRating: { ratingValue: number; bestRating: number; worstRating: number }
  datePublished: string
  author: { name: string; description: string }
  locationCreated: { name: string }
  publisher: { name: string }
}

export interface Page {
  sections: SectionBase[]
  title: string
  head: Partial<{
    title: string
    hotjarScript: string
    clarityScript?: string
    description: string
    pageType?: string
    breadcrumbs: {
      title: string
      href: string
    }[]
    meta: {
      content: string
      name: string
    }[]
    noIndex: boolean
    noFollow: boolean
    canonical: string
    alternate: {
      hrefLang: string
      href: string
    }[]
    locale: string
    countryName: string
    og: {
      title?: string
      image: string
      siteName?: string
    }
    searchPageUrl: string
    rootUrl?: string
    category?: {
      url: string
      name: string
    }
    store?: {
      name: string
      imageURL: string
      url: string
      phone: string
      email: string
      hasMap: string
      address: {
        streetName: string
        city: string
        stateCode: string
        postalCode: string
        countryCode: string
      }
      geo?: {
        latitude: number
        longitude: number
      }
      openingHours?: {
        daysOfWeek: string
        openingTime: string
        closingTime: string
      }[]
    }
    product?: {
      sku: string
      name: string
      model?: string
      imageUrls: {
        url?: string
        altText?: string
      }[]
      description: string
      brandName: string
      colour?: string
      mpn?: string
      offer: {
        sku: string
        url: string
        price: string
        priceValidUntil?: string
        priceCurrency: string
        inStock: boolean
        inStockSchema: string
        hasMerchantReturnPolicy: {
          applicableCountry: string
          returnPolicyCategory: string
          merchantReturnDays: number
          returnMethod: string
          returnFees: string
        }
      }
      documentation: string
      aggregateRating?: {
        ratingValue?: number
        ratingCount?: number
        reviewCount?: number
        bestRating?: number
        worstRating?: number
      }
      review?: ProductReview[]
      specs: { unitCode: string; value: string; name: string }[]
    }
    organization?: {
      name: string
      alternateName: string
      description: string
      logo: string
      contactPoint: {
        phone: string
        contactType: string
        contactOption: string
        areaServed: string
        availableLanguage: string
      }
      subjectOf: string[]
      sameAs: string[]
    }
    projectDetails: {
      name: string
      about: {
        type: string
        attrs: Record<string, unknown>
        uid: string
        children: {
          text: string
        }[]
        _version: number
      }[]
      genre: string
      hasPart: {
        name: string
        url: string
      }[]
    }
  }>
  countryCode?: string
  siteCode?: string
  pageType?: string
  primaryNavigation?: SiteNavigationItem[]
  brand?: string
  faqLink?: { url: string }
  contactUsLink?: { url: string }
  gtagId?: string
  forterToken?: string
  pageHeaderRef?: (node: HTMLElement) => void
  pageHeaderHeight?: number
  sitewideBannerHeight?: number
  pageHeaderMobileDrawerHeight?: number
  pageHeaderFormHeight?: number
  pageWidth?: number
  getSitewideBannerEl?: () => HTMLElement
  getMobileHeaderDrawerEl?: () => HTMLElement
  getHeaderFormEl?: () => HTMLElement
}
