export const countryMap: {
  [key: string]: {
    label: string
    regionFieldName: string
    regions: { label: string; value: string }[]
  }
} = {
  AU: {
    regions: [
      { value: 'ACT', label: 'Australian Capital Territory' },
      { value: 'NSW', label: 'New South Wales' },
      { value: 'NT', label: 'Northern Territory' },
      { value: 'QLD', label: 'Queensland' },
      { value: 'SA', label: 'South Australia' },
      { value: 'TAS', label: 'Tasmania' },
      { value: 'VIC', label: 'Victoria' },
      { value: 'WA', label: 'Western Australia' },
    ],
    regionFieldName: 'State',
    label: 'Australia',
  },
  NZ: {
    regions: [],
    regionFieldName: 'City',
    label: 'New Zealand',
  },
}

export const DEFAULT_COUNTRY_CODE_AU = 'AU'
export const DEFAULT_COUNTRY_CODE_NZ = 'NZ'
export const DEFAULT_COUNTRY_CODE_RTM = 'RTM'
