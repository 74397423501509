import { GTMEvent, GTMEventPriority } from './declarations'
import { PriorityQueue } from './helpers'

import {
  adaptDeactivateAccount,
  adaptCreateAccount,
  adaptLogin,
  adaptRemoveFromCart,
  adaptAddToCart,
  adaptPageLoad,
  adaptSubscribeToNewsletter,
  adaptSelectCheckoutOption,
  adaptViewItem,
  adaptSelectItem,
  adaptViewItemList,
  adaptCartContent,
  adaptAddPaymentInfo,
  adaptAddShippingInfo,
  adaptBeginCheckout,
  adaptViewSearchResults,
  adaptPurchase,
  adaptStoreFinder,
  adaptCheckStoreAvailability,
  adaptViewPromotion,
  adaptSelectPromotion,
  adaptSubmitReturnForm,
  adaptNotifyBackInStock,
  adaptClearEcommerce,
  adaptAddToList,
  adaptCreateList,
  adaptAddToProductComparison,
  adaptUserIdentify,
  adaptServerSideTracking,
  adaptBrazeAddToCart,
  adaptBrazeRemoveFromCart,
  adaptClearCart,
} from './payload'

declare global {
  interface Window {
    dataLayer: undefined | (Array<unknown> & { queue?: unknown })
    gtag: (
      name: string,
      action: string,
      payload: Record<string, unknown>
    ) => void
  }
}
export class GTM {
  static #queue = new PriorityQueue()

  static readonly #getDataLayer = () => {
    window.dataLayer ||= []
    window.dataLayer.queue = this.#queue
    return window.dataLayer
  }

  static readonly #pushEvents = () => {
    const dataLayer = this.#getDataLayer()
    if (!dataLayer) {
      return
    }
    while (!this.#queue.isEmpty()) {
      const queueElement = this.#queue.dequeue()
      const payload = queueElement?.getData()
      if (payload) {
        dataLayer.push(payload)
      }
    }
    this.#queue = new PriorityQueue()
    window.dataLayer.queue = this.#queue
  }

  static readonly #adapterByEvent = {
    [GTMEvent.PAGE_LOAD]: adaptPageLoad,
    [GTMEvent.REMOVE_FROM_CART]: adaptRemoveFromCart,
    [GTMEvent.ADD_TO_CART]: adaptAddToCart,
    [GTMEvent.CART_CONTENTS_PUSH]: adaptCartContent,
    [GTMEvent.SUBSCRIBE_TO_NEWSLETTER]: adaptSubscribeToNewsletter,
    [GTMEvent.DEACTIVATE_ACCOUNT]: adaptDeactivateAccount,
    [GTMEvent.CREATE_ACCOUNT]: adaptCreateAccount,
    [GTMEvent.LOGIN]: adaptLogin,
    [GTMEvent.SELECT_CHECKOUT_OPTION]: adaptSelectCheckoutOption,
    [GTMEvent.VIEW_ITEM]: adaptViewItem,
    [GTMEvent.SELECT_ITEM]: adaptSelectItem,
    [GTMEvent.VIEW_ITEM_LIST]: adaptViewItemList,
    [GTMEvent.ADD_PAYMENT_INFO]: adaptAddPaymentInfo,
    [GTMEvent.ADD_SHIPPING_INFO]: adaptAddShippingInfo,
    [GTMEvent.BEGIN_CHECKOUT]: adaptBeginCheckout,
    [GTMEvent.VIEW_SEARCH_RESULTS]: adaptViewSearchResults,
    [GTMEvent.PURCHASE]: adaptPurchase,
    [GTMEvent.STORE_FINDER]: adaptStoreFinder,
    [GTMEvent.CHECK_STORE_AVAILABILITY]: adaptCheckStoreAvailability,
    [GTMEvent.VIEW_PROMOTION]: adaptViewPromotion,
    [GTMEvent.SELECT_PROMOTION]: adaptSelectPromotion,
    [GTMEvent.SUBMIT_RETURN_FROM]: adaptSubmitReturnForm,
    [GTMEvent.NOTIFY_BACK_IN_STOCK]: adaptNotifyBackInStock,
    [GTMEvent.CLEAR_ECOMMERCE]: adaptClearEcommerce,
    [GTMEvent.ADD_TO_LIST]: adaptAddToList,
    [GTMEvent.CREATE_LIST]: adaptCreateList,
    [GTMEvent.ADD_TO_PRODUCT_COMPARISON]: adaptAddToProductComparison,
    [GTMEvent.USER_IDENTIFY]: adaptUserIdentify,
    [GTMEvent.SERVER_SIDE_TRACKING]: adaptServerSideTracking,
    [GTMEvent.BRAZE_ADD_TO_CART]: adaptBrazeAddToCart,
    [GTMEvent.BRAZE_REMOVE_FROM_CART]: adaptBrazeRemoveFromCart,
    [GTMEvent.CLEAR_CART]: adaptClearCart,
  }

  static readonly #pushEventsIfPossible = () => {
    if (this.#queue.isReady()) {
      this.#pushEvents()
    }
  }

  static enqueue = (event: GTMEvent) => {
    const adapter = this.#adapterByEvent[event]
    let priority = GTMEventPriority.findIndex((item) => {
      return item === event
    })
    if (priority === -1) {
      priority = Number.MAX_SAFE_INTEGER
    }
    return this.#queue.enqueue(adapter, priority)
  }

  static resolve = async (elementId: string, payload) => {
    await this.#queue.getItemById(elementId).resolve(payload)
    this.#pushEventsIfPossible()
  }

  static rollback = (id: string) => {
    this.#queue.throwElementFromQueue(id)
    this.#pushEventsIfPossible()
  }

  static dispatch = (event: GTMEvent, payload?) => {
    const elementId = this.enqueue(event)
    this.resolve(elementId, payload)
  }
}
export { GTMEvent }
